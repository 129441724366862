/** @jsx jsx */
import * as React from "react"
import { jsx } from "theme-ui"
import { hidden } from "@lekoarts/gatsby-theme-cara/src/styles/utils"

const icons = {
    triangle: {
        shape: (
            <polygon
                strokeWidth="1px"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="14.921,2.27 28.667,25.5 1.175,25.5 "
            />
        ),
        viewBox: `0 0 30 30`,
    },
    gamepad: {
        shape: (
            <React.Fragment>
                <g xmlns="http://www.w3.org/2000/svg" id="layer1" transform="translate(-314.15 -497.61)">
                    <path id="rect4249" d="m47.875 0c-4.432 0-8 3.568-8 8v2.6875c-1.4169 0.87248-2.7266 1.9012-3.9062 3.0625-0.0409 0.0402-0.0844 0.0844-0.125 0.125-4.2921 3.3087-7.5968 7.8163-9.1562 13.438l-25.031 90.25c-4.7392 17.083 0.48564 36.232 17.312 41.812l26.656 8.8125c16.827 5.5802 34.179-1.2971 41.781-17.312l21.031-44.281h106.06l21.031 44.281c7.6018 16.015 24.954 22.893 41.781 17.312l26.656-8.8125c16.827-5.5802 22.052-24.73 17.312-41.812l-25.03-90.248c-2.28-8.216-8.25-14.05-15.66-17.187v-2.125c0-4.432-3.568-8-8-8h-46.562c-4.2401 0-7.678 3.2738-7.9688 7.4375h-115.62c-0.29-4.1643-3.731-7.4375-7.971-7.4375h-46.594z" transform="translate(315.66 499.11)"
                          style={{
                              "strokeWidth": 3,
                          }}
                    />
                    <g id="g3897">
                        <path id="path3244" d="m582.63 539.71a15 15 0 0 1 -30 0 15 15 0 1 1 30 0z" style={{
                            "strokeWidth": 2,
                        }}/>
                        <path id="path3246" d="m582.63 591.13a15 15 0 0 1 -30 0 15 15 0 1 1 30 0z" style={{
                            "strokeWidth": 2,
                        }}/>
                        <path id="path3248" d="m578.35 565.47a15 15 0 1 1 30 0 15 15 0 0 1 -30 0z" style={{
                            "strokeWidth": 2,
                        }}/>
                        <path id="path3250" d="m526.93 565.47a15 15 0 1 1 30 0 15 15 0 0 1 -30 0z" style={{
                            "strokeWidth": 2,
                        }}/>
                        <path id="path3324" style={{
                            "strokeWidth": 2,
                        }} d="m366.97 551.5a2.6354 2.6354 0 0 1 1.8862 1.0479l7.824 9.9198a2.6354 2.6354 0 0 1 0 3.2135l-7.824 9.9896a2.6354 2.6354 0 0 1 -2.0957 1.0479l-18.163 0.0698a2.6354 2.6354 0 0 1 -2.6546 -2.6545v-19.63a2.6354 2.6354 0 0 1 2.5847 -2.6546l18.163-0.34927a2.6354 2.6354 0 0 1 0.27944 0z"/>
                        <path id="path3322" style={{
                            "strokeWidth": 2,
                        }} d="m403.99 551.7a2.6354 2.6354 0 0 0 -1.8862 1.0479l-7.8241 9.9198a2.6354 2.6354 0 0 0 0 3.2135l7.8241 9.9896a2.6354 2.6354 0 0 0 2.0957 1.0479l18.163 0.0698a2.6354 2.6354 0 0 0 2.6546 -2.6545v-19.63a2.6354 2.6354 0 0 0 -2.5847 -2.6546l-18.163-0.34927a2.6354 2.6354 0 0 0 -0.27944 0z"/>
                        <path id="path3332" style={{
                            "strokeWidth": 2,
                        }} d="m398.02 582.75a2.6354 2.6354 0 0 0 -1.0479 -1.8862l-9.9198-7.8241a2.6354 2.6354 0 0 0 -3.2135 0l-9.9896 7.8241a2.6354 2.6354 0 0 0 -1.0479 2.0957l-0.0698 18.163a2.6354 2.6354 0 0 0 2.6545 2.6546h19.63a2.6354 2.6354 0 0 0 2.6546 -2.5847l0.34927-18.163a2.6354 2.6354 0 0 0 0 -0.27943z"/>
                        <path id="path3334" style={{
                            "strokeWidth": 2,
                        }} d="m398.23 545.73a2.6354 2.6354 0 0 1 -1.0479 1.8862l-9.9198 7.8241a2.6354 2.6354 0 0 1 -3.2135 0l-9.9897-7.8241a2.6354 2.6354 0 0 1 -1.0479 -2.0957l-0.0698-18.163a2.6354 2.6354 0 0 1 2.6545 -2.6546h19.63a2.6354 2.6354 0 0 1 2.6546 2.5848l0.34929 18.163a2.6354 2.6354 0 0 1 0 0.27942z"/>
                        <path id="path4289" style={{
                            "strokeWidth": 2,
                        }} d="m507.13 552.5c0.69726 0.0598 1.3623 0.42912 1.7813 0.98963l7.3892 9.3685c0.66325 0.86235 0.66325 2.1725 0 3.0349l-7.3892 9.4344c-0.46045 0.61012-1.2149 0.98735-1.9793 0.98964l-21.376 0.0658c-1.3153 0.0105-2.5166-1.1917-2.5071-2.507v-18.539c-0.0105-1.2934 1.1478-2.4822 2.4411-2.5071l21.376-0.32987c0.0878-0.005 0.17607-0.005 0.26391 0z"/>
                        <path id="path4291" style={{
                            "strokeWidth": 2,
                        }} d="m447.44 552.31c-0.69726 0.0598-1.3623 0.42912-1.7813 0.98964l-7.3892 9.3685c-0.66325 0.86235-0.66325 2.1725 0 3.0349l7.3892 9.4344c0.46045 0.61012 1.2149 0.98735 1.9793 0.98964l21.376 0.0658c1.3153 0.0105 2.5166-1.1917 2.5071-2.507v-18.539c0.0105-1.2934-1.1479-2.4822-2.4411-2.5071l-21.376-0.32987c-0.0877-0.005-0.17607-0.005-0.2639 0z"/>
                    </g>
                </g>
                {/*<path d={"m47.875 0c-4.432 0-8 3.568-8 8v2.6875c-1.4169 0.87248-2.7266 1.9012-3.9062 3.0625-0.0409 0.0402-0.0844 0.0844-0.125 0.125-4.2921 3.3087-7.5968 7.8163-9.1562 13.438l-25.031 90.25c-4.7392 17.083 0.48564 36.232 17.312 41.812l26.656 8.8125c16.827 5.5802 34.179-1.2971 41.781-17.312l21.031-44.281h106.06l21.031 44.281c7.6018 16.015 24.954 22.893 41.781 17.312l26.656-8.8125c16.827-5.5802 22.052-24.73 17.312-41.812l-25.03-90.248c-2.28-8.216-8.25-14.05-15.66-17.187v-2.125c0-4.432-3.568-8-8-8h-46.562c-4.2401 0-7.678 3.2738-7.9688 7.4375h-115.62c-0.29-4.1643-3.731-7.4375-7.971-7.4375h-46.594z"}/>*/}
            </React.Fragment>
        ),
        viewBox: `0 0 325.96 173.1`,
    },
    circle: {
        shape: (
            <path d="M15,30A15,15,0,1,1,30,15,15,15,0,0,1,15,30ZM15,6.23A8.77,8.77,0,1,0,23.77,15,8.77,8.77,0,0,0,15,6.23Z" />
        ),
        viewBox: `0 0 30 30`,
    },
    arrowUp: {
        shape: (
            <React.Fragment>
                <path d="M28.74,20.81H1.26a1.26,1.26,0,0,1-1-2L14.16.5a1.25,1.25,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,18.8a1.25,1.25,0,0,1-1,2ZM3.81,18.29H26.22L15.16,3.37Z" />
                {` `}
                <path d="M28.74,42H1.26a1.28,1.28,0,0,1-1.13-.71A1.26,1.26,0,0,1,.26,40l13.9-18.29a1.28,1.28,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,40a1.26,1.26,0,0,1,.12,1.32A1.28,1.28,0,0,1,28.74,42ZM3.81,39.47H26.22L15.16,24.55Z" />
            </React.Fragment>
        ),
        viewBox: `0 0 30 42`,
    },
    upDown: {
        shape: (
            <React.Fragment>
                <path d="M28.74,44.58a1.28,1.28,0,0,1-1-.51L15.16,27.13l-12.89,17a1.26,1.26,0,1,1-2-1.53l13.9-18.29a1.34,1.34,0,0,1,1-.5,1.24,1.24,0,0,1,1,.51L29.75,42.56a1.27,1.27,0,0,1-1,2Z" />
                <path d="M14.83,20.82h0a1.28,1.28,0,0,1-1-.52L.25,2a1.27,1.27,0,0,1,2-1.51L14.84,17.45,27.73.5a1.26,1.26,0,0,1,2,1.53L15.84,20.32A1.28,1.28,0,0,1,14.83,20.82Z" />
            </React.Fragment>
        ),
        viewBox: `0 0 30 44.58`,
    },
    box: {
        shape: (
            <path d="M28,2V28H2V2H28m.13-2H1.88A1.88,1.88,0,0,0,0,1.88V28.13A1.88,1.88,0,0,0,1.88,30H28.13A1.87,1.87,0,0,0,30,28.13V1.88A1.88,1.88,0,0,0,28.13,0Z" />
        ),
        viewBox: `0 0 30 30`,
    },
    hexa: {
        shape: (
            <polygon
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="27.5,21.904 15,28.809  2.5,21.904 2.5,8.095 15,1.19 27.5,8.095 "
            />
        ),
        viewBox: `0 0 30 30`,
    },
    cross: {
        shape: (
            <path
                strokeWidth="3px"
                d="M60.5,50l34.8-34.8c2.9-2.9,2.9-7.6,0-10.5c-2.9-2.9-7.6-2.9-10.5,0L50,39.5L15.2,4.7c-2.9-2.9-7.6-2.9-10.5,0    c-2.9,2.9-2.9,7.6,0,10.5L39.5,50L4.7,84.8c-2.9,2.9-2.9,7.6,0,10.5c1.4,1.4,3.3,2.2,5.2,2.2s3.8-0.7,5.2-2.2L50,60.5l34.8,34.8    c1.4,1.4,3.3,2.2,5.2,2.2c1.9,0,3.8-0.7,5.2-2.2c2.9-2.9,2.9-7.6,0-10.5L60.5,50z"
            />
        ),
        viewBox: `0 0 100 100`,
    },
}

type IconType = "triangle" | "circle" | "arrowUp" | "upDown" | "box" | "hexa" | "cross" | "image" | "gamepad"

type SVGProps = {
    stroke?: boolean
    color?: string | number | any
    borderRadius?: string
    width: number | string
    icon: IconType
    left: string
    top: string
    hiddenMobile?: boolean
    imageLink?: string
}

const Svg = ({ stroke = false, color = ``, width, icon, left, top, hiddenMobile = false, imageLink, borderRadius }: SVGProps) => {
    if (icon === "image") {
        return (<img src={imageLink} sx={{
            "borderRadius": borderRadius ? borderRadius : `100%`,
            position: `absolute`,
            stroke: stroke ? `currentColor` : `none`,
            fill: stroke ? `none` : `currentColor`,
            display: hiddenMobile ? hidden : `block`,
            width,
            left,
            top,
        }}/>)
    } else {
        return (<svg
            sx={{
                position: `absolute`,
                stroke: stroke ? `currentColor` : `none`,
                fill: stroke ? `none` : `currentColor`,
                display: hiddenMobile ? hidden : `block`,
                color,
                width,
                left,
                top,
            }}
            viewBox={icons[icon].viewBox}
        >
            {icons[icon].shape}
        </svg>)
    }
}

export default Svg
